

































import {Component, Prop, Mixins} from 'vue-property-decorator';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import ActionModelMixin from '@/layouts/back-office/elements/actions/ActionModelMixin';
import i18n from '@/store/i18n';

@Component({
  components: {SpinnerButton, Modal},
})
export default class ActionModal extends Mixins(ActionModelMixin) {
  @Prop({ type: Boolean, default: true }) protected guttered!: boolean;

  @Prop({type: String, default: () => i18n.it('global.save', 'Save')}) protected saveText!: string;
}
