import {Component, Prop, Vue} from 'vue-property-decorator';
import i18n from '@/store/i18n';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import {MutationOptions} from 'apollo-client';
import {defaultRefetchQueries} from '@/store/apollo/apollo';
import {RefetchQueryDescription} from 'apollo-client/core/watchQueryOptions';
import {ClientOptions} from 'vue-apollo/types/vue-apollo';

@Component
export default class ActionModelMixin extends Vue {
  @Prop(String) protected title!: string;
  @Prop(String) protected buttonText?: string;
  @Prop(Array) protected items!: any[];

  @Prop(String) protected clientId?: string;
  @Prop(Object) protected mutation!: any;
  @Prop(Array) protected refetchQueries?: any[];
  @Prop(Object) protected variables!: any;
  @Prop({type: Boolean, default: true}) protected canSave!: boolean;
  @Prop({type: Boolean, default: false}) protected single!: boolean;
  @Prop({type: Boolean, default: false}) protected hasUpload!: boolean;

  @Prop({type: String, default: () => {
    return i18n.it('global.fields-need-to-filled.', 'Not all required fields are filled in, please check your input.');
  }})
  protected disabledMessage!: string;

  protected saving: boolean = false;
  protected error: string | null = null;

  get numberOfItems() {
    return this.items.length;
  }

  get errorMessage() {
    if (this.error?.startsWith('GraphQL error: ')) {
      return this.error?.substr(15);
    }

    return this.error;
  }

  public open() {
    (this.$refs.modal as Modal).open();
  }

  public close() {
    this.$emit('close');
    (this.$refs.modal as Modal).close();
  }

  protected async save(spinning: (state?: boolean) => boolean) {
    if (this.canSave) {
      this.error = null;
      this.saving = spinning();

      const options: MutationOptions & ClientOptions = {
        client: this.clientId,
        mutation: this.mutation,
        variables: this.variables,
      };

      if (this.refetchQueries) {
        options.awaitRefetchQueries = true;
        options.refetchQueries = this.refetchQueries.concat(defaultRefetchQueries)
          .reduce((unique: RefetchQueryDescription, query) =>
            unique.includes(query) ? unique : [...unique, query], []);
      }

      // Add context that there is an image uploaded
      if (this.hasUpload) {
        if ('context' in options) {
          options.context.hasUpload = true;
        } else {
          options.context = {hasUpload: true};
        }
      }

      const result = await this.$apollo.mutate(options).catch((error) => {
        this.error = error.message;
      });

      this.saving = spinning(false);

      if (result && result.data) {
        const data: any = Object.values(result.data)[0];
        if (data && typeof data === 'object' && 'errors' in data && data.errors.length > 0) {
          this.onErrorsReceived(data.errors);
        }

        this.done(result.data);
      }
    } else {
      this.$emit('onDisabledPress');
    }
  }

  protected done(data: any) {
    this.close();

    this.$emit('done', data);
  }

  protected onErrorsReceived(errors: string[]) {
    this.$emit('errors', errors);
  }
}
