import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ActionsRegistrarMixin, {ActionItem} from '@/layouts/back-office/elements/actions/ActionsRegistrarMixin';
import slugify from '@/filters/vue/slugify';

@Component
export default class ActionMixin<T1 = any> extends Vue {
  public title: string = '';
  public icon?: string = undefined;
  public permission?: string | string[] = undefined;
  public isActive: boolean = true;

  @Prop({type: Array, default: () => []}) public selected!: T1[];
  @Prop({type: Function, required: false }) protected register!: (item: ActionItem) => ActionsRegistrarMixin;
  @Prop({type: Boolean, required: false }) protected single!: boolean;
  @Prop({type: Boolean, required: false }) protected allowEmpty!: boolean;


  protected declinedPressed: boolean = false;

  private id: string | null = null;
  private registrar: ActionsRegistrarMixin | null = null;

  protected mounted() {
    this.id = slugify(this.title);

    if (this.register && this.isActive) {
      this.registrar = this.register(this.generateActionItem());
    }
  }

  protected beforeDestroy() {
    if (this.registrar) {
      this.registrar.remove(this.id!);
    }
  }

  protected get firstAvailable(): T1|null {
    return this.availableItems[0] ?? null;
  }

  protected get availableItems(): T1[] {
    return this.selected;
  }

  protected get variableIcon(): string | undefined {
    return undefined;
  }

  @Watch('availableItems')
  protected availableItemsChanged() {
    if (this.registrar) {
      this.registrar.update(this.generateActionItem());
    }
  }

  @Watch('variableIcon')
  protected informationUpdated() {
    if (this.registrar) {
      this.registrar.update(this.generateActionItem());
    }
  }

  protected onSelect() {
    const error = 'onSelect methods is not implemented in ' + this.title;

    throw new Error(error);
  }

  protected done() {
    this.$emit('done');
  }

  protected declinedPress() {
    this.declinedPressed = true;
  }

  protected errors(errors: string[]) {
    this.$emit('errors', errors);
  }

  private generateActionItem(): ActionItem {
    return {
      id: this.id!,
      permission: this.permission,
      label: this.title,
      icon: this.icon || this.variableIcon,
      action: this.onSelect,
      available: this.availableItems.length,
      total: this.selected.length,
      single: this.single,
      allowEmpty: this.allowEmpty,
    };
  }
}
